
(() => {

  /* ------------------------
    HeaderMenu
  ------------------------ */
  var HeaderMenu = function ($) {

    var _button,
        _body = $('.js-HeaderMenu'),
        _wrap = $('.js-HeaderMenu-wrap'),
        _clickEvent,
        _resize,
        _scroll,
        _init = function _init() {

          _clickEvent();
        };
        _clickEvent = function _clickEvent() {
          if ($(window).width() <= 899) {
            var clickEventType = window.ontouchstart === null ? "touchstart.js-HeaderMenu-button" : "click.js-HeaderMenu-button";
            $('.js-HeaderMenu-button').on(clickEventType, function (e) {
              if ($(this).hasClass('is-open')) {
                $(this).removeClass('is-open');
                _body.removeClass('is-open');
                _wrap.removeClass('is-open');
                $('html,body').css({
                  'overflow': 'auto',
                  'height': 'auto',
                  'position': ''
                });
                return false;
              } else {
                $(this).addClass('is-open');
                _body.addClass('is-open');
                _wrap.addClass('is-open');
                _wrap.css('top', '0');
                $('html,body').css({
                  'overflow': 'hidden',
                  'height': '100%',
                  'position': 'fixed',
                  'top': '0'
                });
                return false;
              }
              $(window).on('resize', function () {
                _wrap.css({
                  'widht': '100vw',
                  'height': '100vh'

                });
              });
              e.preventDefault();
              return false;
            });
          } else {
            $('.js-HeaderMenu-button').removeClass('is-open');
            _body.removeClass('is-open');
            _wrap.removeClass('is-open');
          }
        };
    return _init;
  }(jQuery);
  HeaderMenu();


  $('a[href^="#"]').click(function(){
    var speed = 500;
    var href= $(this).attr("href");
    var target = $(href == "#" || href == "" ? 'html' : href);
    var position = target.offset().top;
    $("html, body").animate({scrollTop:position}, speed, "swing");
  });

  /* ------------------------
    Expand
  ------------------------ */
  var Expand = function($){

    var _button = $('.js-Expand'),
        _clickEvent,
        _init = function(){
          _clickEvent();
        }
        _clickEvent = function(){
          _button.on('click', function(){
            var index = _button.index(this);
            if($(this).hasClass('is-open')){
              $(this).removeClass('is-open');
            }else{
              $(this).addClass('is-open');
            }
            return false;
          })

        }
    return _init;

  }(jQuery);

  Expand();

  /* ------------------------
    SmoothScroll
  ------------------------ */
  $('a[href^="#"]').click(function(){
    var speed = 500;
    var href= $(this).attr("href");
    var target = $(href == "#" || href == "" ? 'html' : href);
    var position = target.offset().top;
    $("html, body").animate({scrollTop:position}, speed, "swing");
    return false;
  });
  /* ------------------------
    FixedSocialButton
  ------------------------ */
  var FixedSocialButton = function($){

    var _target,
        _start,
        _end,
        _scrollEventPC,
        _scrollEventSP,
        _startPosition,
        _endPosition,
        _init = function(){
          if($(window).width() <= 900){
            if($('.js-FixedSocialButton-sp').get(0)){
              _target = $('.js-FixedSocialButton-sp');
              _start = $('.js-FixedSocialButton-sp-start');
              _end = $('.js-FixedSocialButton-sp-end');
              _scrollEventSP();
            }else{
              return false;
            }
          }else{
            if($('.js-FixedSocialButton-pc').get(0)){
              _target = $('.js-FixedSocialButton-pc');
              _start = $('.js-FixedSocialButton-pc-start');
              _end = $('.js-FixedSocialButton-pc-end');
              _scrollEventPC();
            }else{
              return false;
            }
          }
        }
        _scrollEventPC = function(){
          $(window).scroll(function(){
            var scrollTop = $(this).scrollTop();
            if(!_start){
              return false;
            }
            _startPosition = _start.offset().top;
            _endPosition = _end.offset().top + _end.outerHeight() - _target.height() - 60;
            if(scrollTop <= _startPosition){
              _target.removeClass('is-fixed');
              _target.removeClass('is-end');
              _target.css('top','0');
            }else if(scrollTop > _startPosition && scrollTop < _endPosition){
              _target.addClass('is-fixed');
              _target.removeClass('is-end');
              _target.css('top','88px');
            }else{
              _target.addClass('is-end');
              _target.removeClass('is-fixed');
              _target.css('top','');
            }
          });
        }
        _scrollEventSP = function(){
          $(window).scroll(function(){
            var scrollTop = $(this).scrollTop();
            if(!_start){
              return false;
            }
            _startPosition = _start.offset().top;
            _endPosition = _end.offset().top - _target.outerHeight();
            if(scrollTop <= _startPosition){
              _target.removeClass('is-fixed');
              _target.removeClass('is-end');
              _target.css('top','0');

            }else if(scrollTop > _startPosition && scrollTop < _endPosition){
              _target.addClass('is-fixed');
              _target.removeClass('is-end');
              _target.css({
                'top':'auto',
                'bottom': '0'
              });
            }else{
              _target.addClass('is-end');
              _target.removeClass('is-fixed');
              _target.css('bottom','auto');
            }
          });
        }
    return _init;

  }(jQuery);
  FixedSocialButton();

  /* ------------------------
    FixedBanner
  ------------------------ */
  var FixedBanner = function($){

    var _target,
        _start,
        _end,
        _scrollEventPC,
        _startPosition,
        _endPosition,
        _init = function(){
          if($(window).width() <= 900){
            return false;
          }else{
            if($('.js-FixedBanner-pc').get(0)){
              _target = $('.js-FixedBanner-pc');
              _start = $('.js-FixedBanner-pc-start');
              _end = $('.js-FixedBanner-pc-end');
              _startPosition = _start.offset().top;
              _endPosition = _end.offset().top - _target.height() + 120;
              _scrollEventPC();
            }else{
              return false;
            }
          }
        }
        _scrollEventPC = function(){
          $(window).scroll(function(){
            var scrollTop = $(this).scrollTop();
            if(!_start){
              return false;
            }
            if(scrollTop <= _startPosition){
              _target.removeClass('is-fixed');
              _target.removeClass('is-end');
              _target.css('top','0');
            }else if(scrollTop > _startPosition && scrollTop < _endPosition){
              _target.addClass('is-fixed');
              _target.removeClass('is-end');
              _target.css('top','88px');
            }else{
              _target.addClass('is-end');
              _target.removeClass('is-fixed');
              _target.css({
                // 'top': _end.offset().top - _target.height()
              });
            }
          });
        }
    return _init;

  }(jQuery);
  FixedBanner();


})()
